<template>
	<div id="product">
		<div class="insert">
			<el-button size="medium" icon="el-icon-plus" type="primary" @click="pageConf.insert = true"
				v-if="$pubFun_.verify_permiss($api_.state.Task.Product.add.token,$menu_.state.roleNode)">新建业务
			</el-button>
		</div>
		<div class="table">
			<el-table :data="table.list" style="width: 100%" row-key="l_id" lazy :load="loadModuleForProduct"
				:tree-props="{children: 'children', hasChildren: 'hasChildren'}" :empty-text="table.text_msg"
				v-loading="table.is_loading" element-loading-spinner="el-icon-loading"
				element-loading-background="rgba(0, 0, 0, 0.8)" element-loading-text="获取数据中...">
				<el-table-column prop="id" label="ID" min-width="120">
				</el-table-column>
				<el-table-column prop="title" label="产品名称" min-width="500">
				</el-table-column>
				<el-table-column prop="uname" label="创建人员" min-width="120">
				</el-table-column>
				<el-table-column prop="ctime" label="创建日期" min-width="120">
				</el-table-column>
				<el-table-column label="操作"
					v-if="$pubFun_.verify_permiss($api_.state.Task.Product.edit.token,$menu_.state.roleNode)">
					<template #default="scope">
						<i class="iconfont icon-edit" title="编辑" @click="openEditPage(scope.row)"
							v-if="scope.row.level === 1">&#xe6b7;</i>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<div class="dialog-page">
			<!-- 新建业务 start -->
			<el-dialog title="新建业务" v-model="pageConf.insert" destroy-on-close :width="500">
				<el-form :model="insertForm.datas" :rules="insertForm.rules" ref="formRef">
					<el-form-item>
						<el-select v-model="insertForm.datas.work_type" class="form-select" placeholder="请选择业务类型"
							@change="choseWorkType">
							<el-option v-for="v in workTypeList.options" :label="v.label" :value="v.value">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item prop="title">
						<el-input v-model="insertForm.datas.title" class="form-input"
							v-if="insertForm.datas.work_type > 0" :placeholder="pageConf.title_placeholder">
						</el-input>
					</el-form-item>
					<el-collapse-transition>
						<el-form-item prop="module_id"
							v-if="insertForm.datas.work_type === 1 && workModuleList.options.length > 0">
							<div class="sub-title">关联板块</div>
							<el-checkbox-group v-model="insertForm.datas.module_id">
								<el-checkbox-button v-for="v in workModuleList.options" :label="v.id">{{v.title}}
								</el-checkbox-button>
							</el-checkbox-group>
						</el-form-item>
					</el-collapse-transition>
				</el-form>
				<template #footer>
					<span class="dialog-footer" v-if="insertForm.datas.work_type > 0">
						<el-button type="primary" :loading="pageConf.submit_btn_isload" @click="insertSubmitForm()">确认新建
						</el-button>
						<el-button @click="pageConf.insert = false">取 消</el-button>
					</span>
				</template>
			</el-dialog>
			<!-- 新建业务 end -->
			<!-- 编辑产品 start -->
			<el-dialog title="编辑产品" v-model="pageConf.edit" destroy-on-close :width="500">
				<el-form :model="editForm.datas" :rules="editForm.rules" ref="formRef">
					<el-form-item prop="title">
						<el-input v-model="editForm.datas.title" class="form-input" placeholder="请输入产品名称">
						</el-input>
					</el-form-item>
					<el-collapse-transition>
						<el-form-item prop="module_id" v-if="workModuleList.options.length > 0">
							<div class="sub-title">关联板块</div>
							<el-checkbox-group v-model="editForm.datas.module_id">
								<el-checkbox-button v-for="v in workModuleList.options"
									:checked="editForm.datas.module_id.indexOf(v.id.toString()) > -1" :label="v.id">
									{{v.title}}
								</el-checkbox-button>
							</el-checkbox-group>
						</el-form-item>
					</el-collapse-transition>
				</el-form>
				<template #footer>
					<span class="dialog-footer">
						<el-button type="primary" :loading="pageConf.submit_btn_isload" @click="editSubmitForm()">确认编辑
						</el-button>
						<el-button @click="pageConf.edit = false">取 消</el-button>
					</span>
				</template>
			</el-dialog>
			<!-- 编辑产品 end -->
		</div>
	</div>
</template>

<script type="text/javascript">
	import {
		getCurrentInstance,
		defineComponent,
		ref,
		reactive
	} from 'vue'
	export default defineComponent({
		name: 'Product',
		setup() {
			// 访问 globalProperties
			const ctx = getCurrentInstance().appContext.config.globalProperties

			// 弹出层配置文件
			const pageConf = reactive({
				insert: false,
				edit: false,
				title_placeholder: '',
				submit_btn_isload: false
			})

			// 表单-挂载
			const formRef = ref()

			// 业务板块
			const workModuleList = reactive({
				options: []
			})

			// 获取业务板块
			const getWorkModule = () => {
				// 打开按钮加载状态
				pageConf.submit_btn_isload = true

				// 获取板块信息
				ctx.$request_nl_.post(ctx.$api_.state.Task.BaseInfo.module_info.url)
					.then((respon) => {
						if (respon.Code === 200) {
							workModuleList.options = respon.Data
						} else {
							ctx.$notify({
								title: '温馨提示',
								message: respon.Message,
								type: 'warning'
							})
						}

						// 关闭加载状态
						pageConf.submit_btn_isload = false
					})
			}

			/*产品列表*/
			// 一级表格数据
			const table = reactive({
				list: [],
				is_loading: false,
				text_msg: '没有更多的数据了~'
			})

			// 获取产品列表
			const loadProductList = () => {
				// 重置参数
				table.list = []
				table.is_loading = true

				// 执行请求操作
				ctx.$request_nl_.post(ctx.$api_.state.Task.Product.list.url, {
						work_type: 1
					})
					.then((respon) => {
						if (respon.Code === 200) {
							// 循环存放产品数据
							respon.Data.forEach((v, i) => {
								v.hasChildren = true
								table.list.push(v)
							})

							// 关闭加载状态
							table.is_loading = false
							table.text_msg = '没有更多的数据了~'
						} else {
							// 关闭加载状态
							table.is_loading = false
							table.text_msg = respon.Message
						}
					}).catch(error => {
						table.is_loading = false
						table.text_msg = "服务器连接失败，请稍后重试"
					})
			}

			// 获取关联产品的板块
			const loadModuleForProduct = (tree, treeNode, resolve) => {
				// 执行请求操作
				ctx.$request_nl_.post(ctx.$api_.state.Task.Product.list.url, {
						work_type: 2,
						pid: tree.id
					})
					.then((respon) => {
						if (respon.Code === 200) {
							resolve(respon.Data)
						} else {
							ctx.$notify({
								title: '温馨提示',
								message: respon.Message,
								type: 'warning'
							})
						}
					})
			}
			/*产品列表*/

			/*新建业务*/
			// 新建业务-业务类型
			const workTypeList = reactive({
				options: [{
					label: '新建产品',
					value: 1,
					placeholder: '请输入产品名称'
				}, {
					label: '新建板块',
					value: 2,
					placeholder: '请输入板块名称'
				}]
			})

			// 新建业务-数据表单内容
			const insertForm = reactive({
				datas: {
					work_type: '',
					title: '',
					module_id: []
				},
				rules: {
					title: {
						required: true,
						message: '请输入名称',
						trigger: 'blur'
					},
					module_id: {
						type: 'array',
						required: true,
						message: '请至少关联一个板块',
						trigger: 'change'
					}
				}
			})

			// 新建业务-选择业务类型
			const choseWorkType = (val) => {
				// 输入框placeholder
				workTypeList.options.forEach((v, i) => {
					if (v.value === val) {
						pageConf.title_placeholder = v.placeholder
					}
				})

				// 重置关联板块选择
				insertForm.datas.module_id = []

				// 关闭按钮加载状态
				pageConf.submit_btn_isload = false

				// 无板块信息，请求异步加载
				if (val === 1 && workModuleList.options.length <= 0) {
					getWorkModule()
				}
			}

			// 新建业务-提交业务数据
			const insertSubmitForm = () => {
				formRef.value.validate((valid) => {
					if (valid === true) {
						// 打开按钮加载状态
						pageConf.submit_btn_isload = true

						// 请求接口提交表单数据
						ctx.$request_nl_.post(ctx.$api_.state.Task.Product.add.url, insertForm
								.datas)
							.then((respon) => {
								if (respon.Code === 200) {
									ctx.$notify({
										title: '温馨提示',
										message: respon.Message,
										type: 'success'
									})

									// 重置表单数据
									workModuleList.options = []
									insertForm.datas.work_type = ''
									insertForm.datas.title = ''
									insertForm.datas.module_id = []

									// 重置配置信息
									pageConf.insert = false

									// 重新加载产品列表
									loadProductList()
								} else {
									ctx.$notify({
										title: '温馨提示',
										message: respon.Message,
										type: 'warning'
									})
								}

								// 关闭加载状态
								pageConf.submit_btn_isload = false
							}).catch(error => {
								// 关闭加载状态
								pageConf.submit_btn_isload = false
							})
					}
				})
			}
			/*新建业务*/

			/*编辑产品*/
			// 编辑产品-数据表单内容
			const editForm = reactive({
				datas: {
					id: '',
					title: '',
					module_id: []
				},
				rules: {
					title: {
						required: true,
						message: '请输入产品名称',
						trigger: 'blur'
					},
					module_id: {
						type: 'array',
						required: true,
						message: '请至少关联一个板块',
						trigger: 'change'
					}
				}
			})

			// 编辑产品-打开编辑页面
			const openEditPage = (data) => {
				// 历史赋值
				editForm.datas.id = data.id
				editForm.datas.title = data.title

				let module_ids = data.module_id.split(',')
				editForm.datas.module_id = []
				module_ids.forEach((v, i) => {
					editForm.datas.module_id.push(parseInt(v))
				})

				// 获取板块信息
				if (workModuleList.options.length <= 0) {
					getWorkModule()
				}

				// 打开编辑页面
				pageConf.edit = true
			}

			// 编辑产品-提交业务数据
			const editSubmitForm = () => {
				formRef.value.validate((valid) => {
					if (valid === true) {
						// 打开按钮加载状态
						pageConf.submit_btn_isload = true

						// 请求接口提交表单数据
						ctx.$request_nl_.post(ctx.$api_.state.Task.Product.edit.url, editForm
								.datas)
							.then((respon) => {
								if (respon.Code === 200) {
									ctx.$notify({
										title: '温馨提示',
										message: respon.Message,
										type: 'success'
									})

									// 重置表单数据
									workModuleList.options = []
									editForm.datas.title = ''
									editForm.datas.module_id = []

									// 重置配置信息
									pageConf.edit = false

									// 重新加载产品列表
									loadProductList()
								} else {
									ctx.$notify({
										title: '温馨提示',
										message: respon.Message,
										type: 'warning'
									})
								}

								// 关闭加载状态
								pageConf.submit_btn_isload = false
							}).catch(error => {
								// 关闭加载状态
								pageConf.submit_btn_isload = false
							})
					}
				})
			}
			/*编辑产品*/

			/*初次自动加载*/
			loadProductList()
			/*初次自动加载*/

			return {
				pageConf,
				formRef,
				table,
				loadModuleForProduct,
				workTypeList,
				workModuleList,
				choseWorkType,
				insertForm,
				insertSubmitForm,
				editForm,
				openEditPage,
				editSubmitForm
			}
		}
	})
</script>

<style lang="less">
	#product {
		.el-dialog {
			min-height: 15vw;

			.el-form {
				text-align: center;

				.form-select {
					width: 100%;
				}

				.form-input {
					width: 100%;
					margin-top: 1vw;
				}

				.sub-title {
					margin-top: 1vw;
					text-align: left;
				}

				.el-checkbox-group {
					text-align: left;

					.el-checkbox-button {
						margin: 0.5vw 0.7vw 0 0;

						.el-checkbox-button__inner {
							border: 1px solid #dcdfe6 !important;
							border-radius: 0.2vw !important;
							-webkit-box-shadow: none !important;
							box-shadow: none !important;
						}
					}
				}
			}
		}
	}
</style>
